import React, { useEffect, useState } from "react";

const Mentions = ({ toggleTab, PerfectScrollbar, socket, toggleChatBox }) => {
  const [mentions, setMentions] = useState([]);

  useEffect(() => {
    socket.on("oldMentions", (data) => {
      setMentions((m) => [...m, ...data["mentions"]]);
    });

    socket.on("newAlert", (data) => {
      setMentions((m) => [data, ...m]);
    });
  }, []);

  const todayDate = new Date();

  return (
    <div
      className={`tab-pane fade  ${
        toggleTab === "mentions" ? "active show" : ""
      }`}
      id="mentions"
      role="tabpanel"
    >
      <div className="card mb-sm-3 mb-md-0 contacts_card">
        {/* <div className="card-header chat-list-header text-center">
               <div>
                  <h4 className="mb-1" ref={(el) => el && el.style.setProperty('margin-bottom', 0, 'important')}>Recent Mentions</h4>
               </div>
            </div> */}
        <PerfectScrollbar
          className={`card-body contacts_body p-0 dz-scroll  ${
            toggleChatBox ? "ps ps--active-y" : ""
          }`}
          options={{ suppressScrollX: true, wheelPropagation: false }}
          // id="DZ_W_Contacts_Body1"
        >
          <ul className="contacts">
            {/* <li className="chat_divider">Recent Mentions</li> */}
            {mentions.map((mention) => (
              <li key={`${mention._id}_mentions`}>
                <div className="d-flex bd-highlight">
                  <div className="img_cont primary">
                    <img className="icon" src={mention.profilePhoto} />
                  </div>
                  <div className="d-flex flex-column user_info w-100">
                    <span className="w-100 mb-2" style={{ height: "20px" }}>
                      {mention.username}
                    </span>
                    <p className="mention-text w-100">{mention.message}</p>
                    <div className="d-flex justify-content-between mt-2">
                      <p className="text-primary">{mention.chatRoom}</p>
                      <p className="text-primary">
                        {todayDate.getUTCFullYear() ===
                          Number(
                            mention.timeStamp.split(" ")[0].split("-")[0]
                          ) &&
                        todayDate.getUTCMonth() + 1 ===
                          Number(
                            mention.timeStamp.split(" ")[0].split("-")[1]
                          ) &&
                        todayDate.getUTCDate() ===
                          Number(mention.timeStamp.split(" ")[0].split("-")[2])
                          ? `${
                              mention.timeStamp.split(" ")[1].split(":")[0]
                            } : ${
                              mention.timeStamp.split(" ")[1].split(":")[1]
                            }`
                          : mention.timeStamp.split(" ")[0]}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </PerfectScrollbar>
      </div>
    </div>
  );
};

export default Mentions;
