import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {FreeMode, Scrollbar, Mousewheel} from 'swiper';

SwiperCore.use([FreeMode, Scrollbar, Mousewheel]);

export default function SwiperSlider2({ wallets, icon, image, selectedWallet, setSelectedWallet}) {
  return (
    <>
      <Swiper
        direction={"vertical"}
        slidesPerView={"auto"}
        freeMode={true}
        scrollbar={true}
        mousewheel={true}
        modules={[FreeMode, Scrollbar, Mousewheel]}
        breakpoints={{
          360: {
            direction: "horizontal",
            slidesPerView: "auto",
          },
          650: {
            direction: "horizontal",
            slidesPerView: 2,
            scrollbar: {
              el: ".swiper-scrollbar",
            },
          },
          1200: {
            direction: "vertical",
            slidesPerView: "auto",
            scrollbar: {
              el: ".swiper-scrollbar",
            },
          },
        }}
        className="mySwiper swiper-container card-swiper"
      >
        {wallets.map(({ pubKey, walletName, balance}, index) => (
          <SwiperSlide key={index}>
            <div className="card-bx stacked card">
              <img src={image} alt="" />
              <div className="card-info">
                <p className="mb-1 text-white fs-14">Balance</p>
                <div className="d-flex justify-content-between">
                  <h2 className="num-text text-white mb-5 font-w600">
                    {balance}
                  </h2>
                  {icon}
                </div>
                <div className="d-flex justify-content-between">
                  <div className="text-white">
                    <p className="fs-12 mb-1 op6">Public Key</p>
                    <span
                      onClick={async () => {
                        if ("clipboard" in navigator) {
                          await navigator.clipboard.writeText(
                            pubKey
                          );
                        } else {
                          document.execCommand(
                            "copy",
                            true,
                            pubKey
                          );
                        }}}
					             style={{'cursor': 'pointer'}}
                    >
                      {pubKey.slice(0,4)}...{pubKey.slice(-3)}
                    </span>
                  </div>
                  <div className="text-white">
                    <p className="fs-12 mb-1 op6">Wallet Name</p>
                    <span>{walletName}</span>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}