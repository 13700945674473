import React, { Fragment, useState } from "react";
import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
import ChatBox from "../ChatBox";

const JobieNav = ({ title, onClick: ClickToAddEvent }) => {
  const [toggle, setToggle] = useState("");
  const [noti, setNoti] = useState(0);
  const onClick = (name) => setToggle(toggle === name ? "" : name);
  // const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  return (
    <Fragment>
      <NavHader />
      <ChatBox
        onClick={() => {onClick("chatbox")
                        setNoti(0)}}
        toggle={toggle}
        setNotifications={setNoti}
      />
      <Header
        onNote={() => onClick("chatbox")}
        notification={noti}
        onNotification={() => onClick("notification")}
        onProfile={() => onClick("profile")}
        toggle={toggle}
        title={title}
        onBox={() => onClick("box")}
        onClick={() => ClickToAddEvent()}
      />
      <SideBar />
    </Fragment>
  );
};

export default JobieNav;
