import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const MsgBox = ({
  openMsg,
  PerfectScrollbar,
  offMsg,
  setMessages,
  messages,
  socket
}) => {
  const [toggle, setToggle] = useState(false);
  const [message, setMessage] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isTyping, setTyping] = useState(false);
  const [ref, setRef] = useState();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const profile = userDetails.idToken.payload['picture'];
  const username = userDetails.idToken.payload["cognito:username"];
  const apiURL = 'https://api.alphalibertee.com/'

  const handleSendMessage = (e) => {
    setButtonDisabled(true)
    setMessage("");
    e.preventDefault();
    if (message.trim()){
      if (openMsg.chatName === "Daisy"){
        setMessages((m) => [...m, {'role':'user', 'content':message}])
        setTyping(true)
        fetch(apiURL.concat('ask'), {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({'username':username, 'question':message})
        }).then((response) => {
          if (response.ok){
            return response.json()
          }
          throw response;
        }).then((data) => {
          setTyping(false)
          setMessages((m) => [...m, data['response']])
          setButtonDisabled(false)
        })
      }else{
      socket.emit('message', {
         message: message,
         username: username,
         profilePhoto: profile,
         chatRoom: openMsg.chatName,
       })
      setMessage("");
      setTimeout(setButtonDisabled(false), 600)
      }
    }
  };

  useEffect(() => {
    if (ref) {
      ref.scrollTop = Number.MAX_SAFE_INTEGER;
    }
   }, [messages, ref]);

  console.log(messages)
  return (
    <div
      className={`card chat dz-chat-history-box ${
        openMsg.flag ? "" : "d-none"
      }`}
    >
      <div className="card-header chat-list-header text-center">
        <Link
          to={"#"}
          className="dz-chat-history-back"
          onClick={() => offMsg()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="18px"
            height="18px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <polygon points="0 0 24 0 24 24 0 24" />
              <rect
                fill="#000000"
                opacity="0.3"
                transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000) "
                x="14"
                y="7"
                width="2"
                height="10"
                rx="1"
              />
              <path
                d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z"
                fill="#000000"
                fillRule="nonzero"
                transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997) "
              />
            </g>
          </svg>
        </Link>
        <div>
          <h6 className="mb-1">{openMsg.chatName}</h6>
        </div>
        <div>
        </div>
      </div>
      <PerfectScrollbar
        className={`card-body msg_card_body dz-scroll ${
          openMsg ? "ps ps--active-y" : ""
        } `}
        id="DZ_W_Contacts_Body3"
        options={{suppressScrollX: true, wheelPropagation: false}}
        containerRef = {ref => setRef(ref)}
      >
        {openMsg.chatName !== "Daisy" ? messages?.map((data) => {
          return data.username === username ? (
            <div className="d-flex justify-content-start mb-4" key={data.__id}>
              <div className="img_cont_msg">
                <img className="rounded-circle user_img_msg" alt="" src={data.profilePhoto} />
              </div>
              <div className="msg_cotainer">
                {data.message}
                <span className="msg_time">{data.username}</span>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-end mb-4" key={data.__id}>
              <div className="msg_cotainer_send">
                {data.message}
                <span className="msg_time_send">{data.username}</span>
              </div>
              <div className="img_cont_msg">
                <img className="rounded-circle user_img_msg" alt="" src={data.profilePhoto}/>
              </div>
            </div>
          );
        }) : messages?.map((data, index) => {
          return data.role === "user" ? (
            <div className="d-flex justify-content-start mb-4" key={index}>
              <div className="img_cont_msg">
                <img className="rounded-circle user_img_msg" alt="" src={profile} />
              </div>
              <div className="msg_cotainer">
                {data.content}
                <span className="msg_time">{username}</span>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-end mb-4" key={index}>
              <div className="msg_cotainer_send">
                {data.content}
                <span className="msg_time_send">Daisy</span>
              </div>
              <div className="img_cont_msg">
                <img className="rounded-circle user_img_msg" alt="" src={profile}/>
              </div>
            </div>
          );
        })}
        {isTyping && openMsg.chatName === "Daisy" && <div className="d-flex justify-content-end mb-4" >
            <div className="msg_cotainer_send typing">
              <div class="typing">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="img_cont_msg">
            <img className="rounded-circle user_img_msg" alt="" src={profile}/>
          </div>
        </div>}
      </PerfectScrollbar>
      <div className="card-footer type_msg">
        <form className="input-group" onSubmit={handleSendMessage}>
          <input
            className="form-control"
            placeholder="Type your message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className="input-group-append">
            <button type="submit" className="btn btn-primary" disabled={isButtonDisabled}>
              <i className="fa fa-location-arrow"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MsgBox;
