import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import ResetPassword from "../components/Custom/ResetPassword.js";
import ResetPasswordConfirmation from "../components/Custom/ResetPasswordConfirmation.js";
import { loadingToggleAction } from "../../store/actions/AuthActions.js";
import { startResetPassword, resetPassword } from "../../services/AuthService.js";
import swal from "sweetalert";
// image
import logo from "../../images/logo-full.png";

function ForgotPassword(props) {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function onResetPassword(e) {
    e.preventDefault();
    if (!props.successMessage){
      if (email === '') {
        swal("Oops", "Email cannot be left empty", "error",{ button: "Try Again!",});
        return;
      }
      dispatch(loadingToggleAction(true));
      dispatch(startResetPassword(email));
    }else{
      if (code === '') {
        swal("Oops", "Code cannot be left empty", "error",{ button: "Try Again!",});
        return;
      }
      if (newPassword === '') {
        swal("Oops", "Password cannot be left empty", "error",{ button: "Try Again!",});
        return;
      }
      if (newPassword !== newPasswordConfirmation) {
        swal("Oops", "Password do not match", "error",{ button: "Try Again!",});
        return;
      }
      dispatch(loadingToggleAction(true));
      dispatch(resetPassword(email, code, newPassword, navigate));
    }
}

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <Link to={"/login"}><i class="fa fa-chevron-left fa-2x" aria-hidden="true"></i></Link>
                    <div className="text-center mb-5">
                      <Link to="/login">
                        <img
                          src={logo}
                          alt=""
                          style={{ maxWidth: "75%", maxHeight: "75%" }}
                        />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Forgot Password</h4>

                    <form onSubmit={onResetPassword}>
                      {props.successMessage.includes("6 digit reset code") && (
                        <>
                          <div className="border p-1 mb-3 text-center primary-text-color">
                            {props.successMessage}
                          </div>
                          <ResetPasswordConfirmation 
                          code={code} 
                          setCode={setCode} 
                          newPassword={newPassword} 
                          setNewPassword={setNewPassword}
                          newPasswordConfirmation={newPasswordConfirmation}
                          setNewPasswordConfirmation={setNewPasswordConfirmation}
                          />
                        </>
                      )}
                      {!props.successMessage && (
                        <ResetPassword email={email} setEmail={setEmail} />
                      )}
                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Reset Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(ForgotPassword);
