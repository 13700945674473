/// Menu
import React, {useEffect, useState } from "react";

/// Link
import { Link } from "react-router-dom";

import { MenuList } from "./Menu";


const SideBar = () => {
  const [activeMenu, setActiveMenu] = useState();
  
  useEffect(() => {
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];
    setActiveMenu(path)
  }, [window.location.pathname])

  console.log(activeMenu)

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  return (
    <div className="deznav">
      <div className="main-profile">
        <div className="image-bx">
          <img src={userDetails.idToken.payload["picture"]} alt="" />
        </div>
        <h5 className="mb-0 fs-16 text-black">
          <span className="font-w400">Hello, </span>
          {userDetails.idToken.payload.name}
        </h5>
        <p className="mb-0 fs-12 font-w400">
          {userDetails.idToken.payload.email}
        </p>
      </div>
      <ul className="metismenu" id="menu">
        <li className="nav-label first">Main Menu</li>
        {MenuList.map((data, index) => {
          return (
            <li
              key={index}
              className={`${
                activeMenu === data.to ? "mm-active" : ""
              }`}
            >
              <>
                <Link
                  to={data.to}
                  className={data.hasMenu ? "has-arrow" : ""}
                  onClick={() => {
                    setActiveMenu(data.to);
                  }}
                >
                  {data.title}
                </Link>
              </>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SideBar;
