import React,{useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect, useDispatch,  } from 'react-redux';
import {loadingToggleAction,} from '../../store/actions/AuthActions';
import { signUp } from "../../services/AuthService";
import swal from "sweetalert";
// image
import logo from "../../images/logo-full.png";

function Register(props) {
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const dispatch = useDispatch();
	  const navigate = useNavigate();

    function onSignUp(e) {
        e.preventDefault();
        const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
        const nameRegex = /.+/
        if (name === '') {
          swal("Oops", "Name cannot be left empty", "error",{ button: "Try Again!",});
          return;
        }
        if (!nameRegex.test(name)) {
          swal("Oops", "Name cannot be left empty", "error",{ button: "Try Again!",});
          return;
        }
        if (email === '') {
          swal("Oops", "Email/Username cannot be left empty", "error",{ button: "Try Again!",});
          return;
        }
        if (username === '') {
          swal("Oops", "Username cannot be left empty", "error",{ button: "Try Again!",});
          return;
        }
        if (password === '') {
          swal("Oops", "Password cannot be left empty", "error",{ button: "Try Again!",});
          return;
        } 
        if (password !== confirmPassword){
          swal("Oops", "Password do not match", "error",{ button: "Try Again!",});
          return;
        }
        if (!passwordRegex.test(password)) {
          swal("Oops", "Password does not meet requirements", "error",{ button: "Try Again!",});
          return;
        }
        dispatch(loadingToggleAction(true));
        dispatch(signUp(name, email, username, password, navigate));
    }
  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-10 col-lg-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                  <Link to={"/login"}><i class="fa fa-chevron-left fa-2x" aria-hidden="true"></i></Link>
                    <div className="text-center mb-5">
                      <Link to="/login">
                        <img src={logo} alt="" style={{'maxWidth': '75%', 'maxHeight': '75%'}}/>
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Sign up your account</h4>
                    <form onSubmit={onSignUp}>
                      <div className="form-group row">
                        <div className="col-12 mb-3">
                          <label className="mb-1"><strong>Name</strong></label>
                          <input type="text" className="form-control col-6 input" placeholder="John Doe" value={name} onChange={(e) => setName(e.target.value)}/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-12 mb-3">
                          <label className="mb-1"><strong>Email</strong></label>
                          <input type="text" className="form-control col-6 input" placeholder="hello@example.com" value={email} onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                          <label className="mb-1"><strong>Username</strong></label>
                          <input type="text" className="form-control col-6 input" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)}/>
                        </div>
                      <div className="form-group row">
                        <div className="col-sm-12 col-md-6 mb-3">
                          <label className="mb-1"><strong>Password</strong></label>
                          <input className="form-control col-6 input" placeholder="" type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-3">
                          <label className="mb-1"><strong>Confirm Password</strong></label>
                          <input type="password" className="form-control col-6 input" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
                        </div>
                      </div>
                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Sign me up
                        </button>
                      </div>
                    </form>
                    <div className="new-account mt-3">
                      <p className="">
                        Already have an account?{" "}
                        <Link className="text-primary" to="/login">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};

export default connect(mapStateToProps)(Register);

