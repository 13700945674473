import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from "react-bootstrap";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { ThemeContext } from "../../../context/ThemeContext";


import Donut from "../zenix/MyWallets/Donut";
import WalletTab from "../zenix/MyWallets/WalletTab";
import ContactSlider from "../zenix/Home/ContactSlider";

import { Nav, Tab } from 'react-bootstrap';
import SwiperSlider2 from "../zenix/MyWallets/SwiperSlider2";

import Card1 from './../../../images/card/card1.jpg';
import Card2 from './../../../images/card/card2.jpg';
import Card3 from './../../../images/card/card3.jpg';

function Bitcoin() {
	return (
		<>
			<svg width="36" height="36" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M31.5488 30.9737H27.61V36.825H31.5488C32.3438 36.825 33.0813 36.5025 33.5988 35.9612C34.14 35.4425 34.4625 34.7062 34.4625 33.8875C34.4638 32.2862 33.15 30.9737 31.5488 30.9737Z"
					fill="white"
				/>
				<path
					d="M30 8.12496C17.9375 8.12496 8.125 17.9375 8.125 30C8.125 42.0625 17.9375 51.875 30 51.875C42.0625 51.875 51.875 42.0612 51.875 30C51.875 17.9387 42.0612 8.12496 30 8.12496ZM34.4512 40.13H31.8712V44.185H29.165V40.13H27.6787V44.185H24.96V40.13H20.18V37.585H22.8175V22.335H20.18V19.79H24.96V15.8162H27.6787V19.79H29.165V15.8162H31.8712V19.79H34.2212C35.5337 19.79 36.7437 20.3312 37.6075 21.195C38.4712 22.0587 39.0125 23.2687 39.0125 24.5812C39.0125 27.15 36.985 29.2462 34.4512 29.3612C37.4225 29.3612 39.8187 31.78 39.8187 34.7512C39.8187 37.7112 37.4237 40.13 34.4512 40.13Z"
					fill="white"
				/>
				<path
					d="M33.2888 27.38C33.7613 26.9075 34.0488 26.2737 34.0488 25.56C34.0488 24.1437 32.8975 22.9912 31.48 22.9912H27.61V28.14H31.48C32.1825 28.14 32.8275 27.84 33.2888 27.38Z"
					fill="white"
				/>
			</svg>
		</>
	)
}
function Ethereum() {
	return (
		<>
			<svg width="36" height="36" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M30.3962 8.12284C18.3333 7.91034 8.34535 17.5482 8.13284 29.6112C7.90784 41.6617 17.5457 51.6496 29.6087 51.8746C41.6717 52.0871 51.6596 42.4492 51.8721 30.3987C52.0846 18.3358 42.4592 8.34785 30.3962 8.12284ZM30.0025 14.3581L36.954 26.7598L30.61 23.2297C30.2312 23.0197 29.7725 23.0197 29.3937 23.2297L23.0497 26.7598L30.0025 14.3581ZM30.0025 45.6381L23.0497 33.2364L29.3937 36.7665C29.5825 36.8715 29.7925 36.924 30.0012 36.924C30.21 36.924 30.42 36.8715 30.6087 36.7665L36.9528 33.2364L30.0025 45.6381ZM30.0025 34.2426L22.3722 29.9975L30.0025 25.7523L37.6315 29.9975L30.0025 34.2426Z"
					fill="white"
				/>
			</svg>
		</>
	)
}
function Solana() {
	return (
		<>
			<svg width="36" height="36" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M30 8.125C17.9375 8.125 8.125 17.9375 8.125 30 8.125 42.0625 17.9375 51.875 30 51.875 42.0625 51.875 51.875 42.0612 51.875 30 51.875 17.9387 42.0612 8.125 30 8.125Zm11 33.875L14 42 19 36 46 36ZM46 33 19 33 14 27 41 27ZM41 24 14 24 19 18 46 18Z"
					fill="white"
				/>
			</svg>
		</>
	)
}

const CoinChart = loadable(() =>
	pMinDelay(import("../zenix/MyWallets/CoinChart"), 1000)
);


const Wallet = () => {
	const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  	const username = userDetails.idToken.payload['cognito:username']
	const { background } = useContext(ThemeContext);
	const [wallets, setWallets] = useState({
		'solana': [],
		'bitcoin': [],
		'ethereum': []
	});
	const [activeMenu, setActiveMenu] = useState('bitcoin')
	const [selectedWallet, setSelectedWallet] = useState(wallets.bitcoin[0]?.pubKey)
	const [crrency1, setCrrency1] = useState("Monthly (2023)");	

	const apiURL = 'https://api.alphalibertee.com/'
	useEffect(() => {
		fetch(apiURL.concat(`/sol/balance?username=${username}`)).then( response =>
			response.json()
		).then( response =>
			setWallets((m) => (m['solana']=response, m))
		)

		fetch(apiURL.concat(`/btc/balance?username=${username}`)).then( response =>
			response.json()
		).then( response =>
			setWallets((m) => (m['bitcoin']=response, m))
		)

		fetch(apiURL.concat(`/eth/balance?username=${username}`)).then( response =>
			response.json()
		).then( response =>
			setWallets((m) => (m['ethereum']=response, m))
		)
	},[])

	return (
		<Fragment>
			<div className="form-head mb-sm-5 mb-3 d-flex align-items-center flex-wrap">
				<h2 className="font-w600 mb-0 me-auto mb-2 text-black">My Wallets</h2>
				<div className="card-header pb-2 d-block d-sm-flex flex-wrap border-0">
					<div className="card-action card-tabs mb-3 style-1">
					<Tab.Container defaultActiveKey="bitcoin" activeKey={activeMenu} onSelect={(key) => setActiveMenu(key)}>
							<Nav as="ul" className="nav-tabs" role="tablist">
								<Nav.Item as="li" className="nav-item">
									<Nav.Link to={"#"} eventKey="bitcoin">
										Bitcoin
									</Nav.Link>
								</Nav.Item>
								<Nav.Item as="li" className="nav-item">
									<Nav.Link to={"#"} eventKey="ethereum">
										Ethereum
									</Nav.Link>
								</Nav.Item>
								<Nav.Item as="li" className="nav-item">
									<Nav.Link to={"#"} eventKey="solana">
										Solana
									</Nav.Link>
								</Nav.Item>
							</Nav>
						</Tab.Container>
					</div>
				</div>
				<Link to={"#"} className="btn btn-secondary mb-2 me-3">
					Import Wallet
				</Link>
				<Link to={"#"} className="btn btn-secondary mb-2">
					Create Wallet
				</Link>
			</div>
			<div className="row">
				<div className="col-xl-3 col-xxl-4">
					<div className="swiper-box">
						<SwiperSlider2 wallets={wallets[activeMenu]} 
						icon={activeMenu == 'bitcoin'? <Bitcoin/> : activeMenu === 'solana'? <Solana/>: <Ethereum/>} 
						image={activeMenu == 'bitcoin'? Card1 : activeMenu === 'solana'? Card2: Card3}
						selectedWallet={selectedWallet}
						setSelectedWallet={setSelectedWallet}
						/>
					</div>
				</div>
				<div className="col-xl-9 col-xxl-8">
					<div className="row">
						<div className="col-xl-12">
							<div className="d-block d-sm-flex mb-4">
								<h4 className="mb-0 text-black fs-20 me-auto">Card Details</h4>
							</div>
						</div>
						<div className="col-xl-12">
							<div className="card">
								<div className="card-body">
									<div className="row align-items-end">
										<div className="col-xl-6 col-lg-12 col-xxl-12">
											<div className="row">
												<div className="col-sm-6">
													<div className="mb-4">
														<p className="mb-2">Card Name</p>
														<h4 className="text-black">Main Balance</h4>
													</div>
													<div className="mb-4">
														<p className="mb-2">Valid Date</p>
														<h4 className="text-black">08/21</h4>
													</div>
													<div>
														<p className="mb-2">Card Number</p>
														<h4 className="text-black">**** **** **** 1234</h4>
													</div>
												</div>
												<div className="col-sm-6">
													<div className="mb-4">
														<p className="mb-2">Bank Name</p>
														<h4 className="text-black">ABC Center Bank</h4>
													</div>
													<div className="mb-4">
														<p className="mb-2">Card Holder</p>
														<h4 className="text-black">Marquezz Silalahi</h4>
													</div>
													<div>
														<p className="mb-2">Card Theme</p>
														<div
															className="btn-group theme-colors"
															data-toggle="buttons"
														>
															<label className="btn btn-primary btn-sm active">
																<input
																	type="radio"
																	className="position-absolute invisible"
																	name="options"
																	id="option5"
																/>{" "}
																<i className="las la-check-circle"></i>
															</label>
															<label className="btn btn-warning btn-sm">
																<input
																	type="radio"
																	className="position-absolute invisible"
																	name="options"
																	id="option1"
																	defaultChecked=""
																/>
																<i className="las la-check-circle"></i>
															</label>
															<label className="btn btn-success btn-sm">
																<input
																	type="radio"
																	className="position-absolute invisible"
																	name="options"
																	id="option2"
																/>{" "}
																<i className="las la-check-circle"></i>
															</label>
															<label className="btn btn-info btn-sm">
																<input
																	type="radio"
																	className="position-absolute invisible"
																	name="options"
																	id="option3"
																/>{" "}
																<i className="las la-check-circle"></i>
															</label>
															<label className="btn btn-secondary btn-sm">
																<input
																	type="radio"
																	className="position-absolute invisible"
																	name="options"
																	id="option4"
																/>{" "}
																<i className="las la-check-circle"></i>
															</label>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-6 col-lg-12 col-xxl-12 mb-lg-0 mb-3">
											<p>Monthly Limits</p>
											<div className="row">
												<div className="col-sm-4 mb-sm-0 mb-4 text-center">
													<div className="d-inline-block position-relative donut-chart-sale mb-3">
														{background.value === "dark" ? (
															<Donut
																value={66}
																backgroundColor="rgb(255, 104, 38)"
																backgroundColor2="#2c254a"
															/>
														) : (
															<Donut
																value={66}
																backgroundColor="rgb(255, 104, 38)"
																backgroundColor2="#eee"
															/>
														)}
														<small>66%</small>
													</div>
													<h5 className="fs-18 text-black">Main Limits</h5>
													<span>$10,000</span>
												</div>
												<div className="col-sm-4 mb-sm-0 mb-4 text-center">
													<div className="d-inline-block position-relative donut-chart-sale mb-3">
														{background.value === "dark" ? (
															<Donut
																value={45}
																backgroundColor="#1DC624"
																backgroundColor2="#2c254a"
															/>
														) : (
															<Donut
																value={45}
																backgroundColor="#1DC624"
																backgroundColor2="#eee"
															/>
														)}
														<small>45%</small>
													</div>
													<h5 className="fs-18 text-black">Seconds</h5>
													<span>$500</span>
												</div>
												<div className="col-sm-4 text-center">
													<div className="d-inline-block position-relative donut-chart-sale mb-3">
														{background.value === "dark" ? (
															<Donut
																value={25}
																backgroundColor="#9E9E9E"
																backgroundColor2="#2c254a"
															/>
														) : (
															<Donut
																value={25}
																backgroundColor="#9E9E9E"
																backgroundColor2="#eee"
															/>
														)}
														<small>25%</small>
													</div>
													<h5 className="fs-18 text-black">Others</h5>
													<span>$100</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-12">
							<div className="card">
								<div className="card-header d-block d-sm-flex border-0 pb-sm-0 pb-0 align-items-center select-btn">
									<div className="me-auto mb-sm-0 mb-3">
										<h4 className="fs-20 text-black">Coin Chart</h4>
										<p className="mb-0 fs-12">
											Lorem ipsum dolor sit amet, consectetur
										</p>
									</div>
									<Dropdown>
										<Dropdown.Toggle
											variant=""
											className="form-control style-1 default-select"
										>
											{crrency1}
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item
												onClick={() => setCrrency1("Monthly (2023)")}
											>
												Monthly (2023)
											</Dropdown.Item>
											<Dropdown.Item
												onClick={() => setCrrency1("Daily (2023)")}
											>
												Daily (2023)
											</Dropdown.Item>
											<Dropdown.Item
												onClick={() => setCrrency1("Weekly (2023)")}
											>
												Weekly (2023)
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>
								<div className="card-body pt-3">
									<div className="flex-wrap mb-sm-4 mb-2 align-items-center">
										<div className="d-flex align-items-center">
											<span className="fs-32 text-black font-w600 pr-3">
												$557,235.31
											</span>
											<span className="fs-22 text-success">
												7%{" "}
												<i
													className="fa fa-caret-up scale5 ms-2 text-success"
													aria-hidden="true"
												></i>
											</span>
										</div>
										<p className="mb-0 text-black me-auto">
											Last Week <span className="text-success">$563,443</span>
										</p>
									</div>
									<div
										id="chartTimeline"
										className="timeline-chart market-line"
									>
										<CoinChart />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-6  mt-4">
					<div className="row">
						<div className="col-xl-12">
							<WalletTab activeMenu="Wallet Activity" />
						</div>
					</div>
				</div>
				<div className="col-xl-6 mt-4">
					<div className="row">
						<div className="col-xl-12">
							<div className="card">
								<div className="card-header d-sm-flex d-block pb-0 border-0">
									<div>
										<h4 className="fs-20 text-black">Quick Transfer</h4>
										<p className="mb-0 fs-12">
											Lorem ipsum dolor sit amet, consectetur
										</p>
									</div>
									<Dropdown className="custom-dropdown d-block mt-3 mt-sm-0 mb-0">
										<Dropdown.Toggle
											variant=""
											className="btn btn-light btn-sm d-flex align-items-center svg-btn i-false"
											data-toggle="dropdown"
										>
											<svg
												className="me-2"
												width="25"
												height="25"
												viewBox="0 0 42 42"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M21 0C9.40213 0 0.00012207 9.40201 0.00012207 20.9999C0.00012207 32.5978 9.40213 41.9998 21 41.9998C32.5979 41.9998 41.9999 32.5978 41.9999 20.9999C41.9867 9.4075 32.5924 0.0132751 21 0ZM28.5 31.5001H16.5002C15.6717 31.5001 15.0001 30.8286 15.0001 30C15.0001 29.929 15.0052 29.8581 15.0152 29.7876L16.1441 21.8843L13.864 22.4547C13.7449 22.4849 13.6227 22.5 13.5 22.5C12.6715 22.4991 12.0009 21.8271 12.0013 20.9985C12.0022 20.311 12.4701 19.7122 13.137 19.5447L16.6018 18.6786L18.015 8.78723C18.1321 7.96692 18.892 7.39746 19.7123 7.51465C20.5327 7.63184 21.1021 8.39172 20.9849 9.21204L19.7444 17.8931L25.1364 16.545C25.9388 16.3403 26.755 16.8251 26.9592 17.6276C27.1638 18.43 26.679 19.2462 25.8766 19.4508C25.872 19.4518 25.8674 19.4531 25.8629 19.454L19.2857 21.0983L18.2287 28.4999H28.5C29.3286 28.4999 30.0001 29.1714 30.0001 30C30.0001 30.8281 29.3286 31.5001 28.5 31.5001Z"
													fill="#5974D5"
												/>
											</svg>
											<span className="text-black fs-16">23,511 LTC</span>
											<i className="fa fa-angle-down text-black scale3 ms-2"></i>
										</Dropdown.Toggle>
										<Dropdown.Menu
											className="dropdown-menu-right"
											alignRight={true}
										>
											<Link className="dropdown-item" to="#">
												345,455 ETH
											</Link>
											<Link className="dropdown-item" to="#">
												789,123 BTH
											</Link>
										</Dropdown.Menu>
									</Dropdown>
								</div>
								<div className="card-body">
									<div className="form-wrapper">
										<div className="form-group">
											<div className="input-group input-group-lg">
												<div className="input-group-prepend">
													<span className="input-group-text">Amount BTC</span>
												</div>
												<input
													type="number"
													className="form-control"
													placeholder="742.2"
												/>
											</div>
										</div>
									</div>
									<div className="d-flex mb-3 justify-content-between align-items-center">
										<h4 className="text-black fs-20 mb-0">Recent Contacts</h4>
										<Link to={"#"} className="btn-link">
											View more
										</Link>
									</div>
									<div className="testimonial-one px-4 owl-right-nav owl-carousel owl-loaded owl-drag">
										<ContactSlider />
									</div>
									<div className="row pt-5 align-items-center">
										<div className="col-sm-6 mb-2">
											<p className="mb-0 fs-14">
												Lorem ipsum dolor sit amet, consectetur adipiscing elit,
												sed do eiusmod tempor incididunt ut
											</p>
										</div>
										<div className="col-sm-6 mb-2">
											<Link to={"#"} className="btn btn-secondary d-block">
												TRANSFER NOW
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
export default Wallet;