import React from "react";

function ResetPassword({ email, setEmail }) {
  return (
    <div className="form-group">
      <label className="mb-2">
        <strong>Email</strong>
      </label>
      <input
        className="form-control input"
        placeholder="hello@example.com"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
    </div>
  );
}

export default ResetPassword;
