export const MenuList = [
  //Dashboard
  {
    title: "Dashboard",
    to: "dashboard",
  },
  {
    title: "Wallet",
    to: "my-wallets",
  },
  {
    title: "Transactions",
    to: "transactions",
  },
  {
    title: "Coin Details",
    to: "coin-details",
  },
  {
    title: "Portofolio",
    to: "portofolio",
  },
  {
    title: "Market Capital",
    to: "market-capital",
  },
];
