import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { loadingToggleAction,
} from '../../store/actions/AuthActions';
import { login, reset_message_action } from '../../services/AuthService';
import swal from 'sweetalert';

//
import logo from '../../images/logo-full.png'
import loginpic from "../../images/bg-login2.png";
import loginbg from "../../images/bg-login.jpg";
import UserPool from '../../services/UserPool';

function Login (props) {
	const navigate = useNavigate()
    var lastAuth = localStorage.getItem('CognitoIdentityServiceProvider.'+(UserPool.getClientId())+'.LastAuthUser')
    if (lastAuth === null) {lastAuth = '';}
    const [username, setUsername] = useState(lastAuth);
    const [password, setPassword] = useState('');
    const [message, ] = useState(props.successMessage);
    const dispatch = useDispatch();

    function onLogin(e) {
      e.preventDefault();
      if (username === '') {
        swal("Oops", "Email/Username cannot be left empty", "error",{ button: "Try Again!",});
        return;
      }
      if (password === '') {
        swal("Oops", "Password cannot be left empty", "error",{ button: "Try Again!",});
        return;
      }
		  dispatch(loadingToggleAction(true));	
      dispatch(login(username, password, navigate));
    }

      async function onForgotPasswordClick (navigate) {
        await dispatch(reset_message_action())
        console.log(props.successMessage)
        navigate('./page-forgot-password')
      }

    return (
        <div className="login-main-page" style={{backgroundImage:"url("+ loginbg +")"}}>
            <div className="login-wrapper">
                <div className="login-aside-left" style={{backgroundImage:"url("+ loginpic +")"}}>
                    {/* <Link to="/dashboard" className="login-logo"> */}
                        <img src={logo} alt="" className="login-logo" style={{'maxWidth': '75%', 'maxHeight': '75%'}}/>
                    {/* </Link> */}
                    <div className="login-description">
                        <h2 className="text-black  mb-2">Why Us?</h2>
                        <p className="fs-12 text-dark">We gamify finance with web3 and our AI Daisy.</p>
                        <ul className="social-icons mt-4">
                            <li><a href="https://www.instagram.com/alphalibertee/?hl=en"><i className="fa fa-instagram"></i></a></li>
                            <li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
                            <li><a href='https://www.linkedin.com/company/alpha-libert%C3%A9e'><i className="fa fa-linkedin"></i></a></li>
                        </ul>
                        {/* <div className="mt-5">
                            <Link to={"#"} className="text-black me-4">Privacy Policy</Link>
                            <Link to={"#"} className="text-black me-4">Contact</Link>
                        </div> */}
                    </div>
                </div>
                <div className="login-aside-right">
                    <div className="row m-0 justify-content-center h-100 align-items-center">
                      <div className="col-xl-7 col-xxl-7">
                        <div className="authincation-content">
                          <div className="row no-gutters">
                            <div className="col-xl-12">
                              <div className="auth-form-1">
                                <div className="mb-3">
                                    <h3 className="text-primary mb-1">Welcome to <br></br> <strong className='primary-text-color'>Alpha Libertee</strong></h3>
                                </div>
                                {message.includes('Confirm Your') && (
                                    <div className='border p-1 mb-3 text-center primary-text-color'>
                                        Account Successfully Created
                                        <br></br>
                                        {message}
                                    </div>
                                )}
                                {message.includes('successful reset') && (
                                    <div className='border p-1 mb-3 text-center primary-text-color'>
                                        {message}
                                    </div>
                                )}
                                <form onSubmit={onLogin}>
                                    <div className="form-group mb-3">
                                        <label className="mb-2 ">
                                          <strong>Username</strong>
                                        </label>
                                        <input type="" className="form-control input"
                                          value={username}
                                          placeholder='Username or Email'
                                          onChange={(e) => setUsername(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="mb-2"><strong>Password</strong></label>
                                        <input
                                          type="password"
                                          className="form-control input"
                                          value={password}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                        />
                                          <p style={{textAlign: 'left'}}>
                                            {/* <Link to="./page-forgot-password"> */}
                                            <Link onClick={(e) => onForgotPasswordClick(navigate)}>
                                            Forgot Password?
                                            </Link>
                                          </p>
                                    </div>
                                  <div className="text-center">
                                    <button
                                      type="submit"
                                      className="btn btn-primary btn-block w-100 signin"
                                    >
                                      Sign In
                                    </button>
                                  </div>
                                </form>
                                <div className="new-account mt-2">
                                  <p className="">
                                    Don't have an account?{" "}
                                    <Link className="text-primary-hover" to="./page-register">
                                      Sign up
                                    </Link>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>           
    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Login);