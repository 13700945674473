import swal from "sweetalert";
import UserPool from './UserPool';
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import {Logout,} from '../store/actions/AuthActions';
import socket from '../socket'


export function signUp(name, email, username, password, navigate) {
    const defaultProfilePictureURL = 'https://alphaliberteeuserprofilephotos.s3.amazonaws.com/DefaultProfilePhoto.png';
    const apiURL = 'https://api.alphalibertee.com/'
    return (dispatch) => {
        fetch(apiURL.concat('createUser'), {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({'username':username, 'email':email, 'name':name})
        }).then((response) => {
            if (response.ok) {
                UserPool.signUp(username, password, [
                    new CognitoUserAttribute({Name: 'email', Value: email}),
                    new CognitoUserAttribute({Name: 'name', Value: name}),
                    new CognitoUserAttribute({Name: 'picture', Value: defaultProfilePictureURL})
                ], null, (err, data) => {
                    if (err) {
                        const errorMessage = formatError(err.name, err.message);
                        dispatch(signupFailedAction(errorMessage));
                    } else{
                        saveTokenInLocalStorage(data);
                        runLogoutTimer(
                            dispatch,
                            3600000,
                            navigate,
                        );
                        dispatch(confirmedSignupAction(data));              
                        navigate('/login');
                    }
                })
            } else {
                const errorMessage = formatError('UsernameExistsInDatabaseException', '');
                dispatch(signupFailedAction(errorMessage));
            }
        })
    }
}
export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}
export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function login(username, password, navigate) {
    const user = new CognitoUser({
        Email: username,
        Username: username,
        Pool: UserPool,
    });
    const authDetails = new AuthenticationDetails({
        Email: username,
        Username: username,
        Password: password,
    });
    return (dispatch) => {
        user.authenticateUser(authDetails, {
            onSuccess: (data) => {
                saveTokenInLocalStorage(data);
                runLogoutTimer(
                    dispatch,
                    3600000,
                    navigate,
                );
               dispatch(loginConfirmedAction(data));
				//history.push('/dashboard');                
				navigate('/dashboard');
            },
            onFailure: (err) => {
                if (err.name === "UserNotConfirmedException"){user.resendConfirmationCode((err, data) => {
                    if (err) {console.log(err)}
                    else {console.log(data)}
                })}
                const errorMessage = formatError(err.name, err.message);
                dispatch(loginFailedAction(errorMessage));
            },
            newPasswordRequired: (data) => {
                console.log("newPasswordRequired: ", data);
            }
        })
    }
}
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}
export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export const PASSWORD_RESET_STARTED_ACTION = '[reset action] started password reset';
export const PASSWORD_RESET_CONFIRMED_ACTION = '[reset action] confirmed password reset';
export function startResetPassword(email) {
    const user = new CognitoUser({
        Email: email,
        Username: email,
        Pool: UserPool,
    });
    return (dispatch) => {
        user.forgotPassword({   
            onSuccess: (data) => {
                dispatch(startResetPasswordAction(data));
            },
            onFailure: (err) => {
                console.log(err)
                formatError(err.name, err.message);
            },
        })
    }
}
export function resetPassword(email, confirmationCode, newPassword, navigate) {
    const user = new CognitoUser({
        Email: email,
        Username: email,
        Pool: UserPool,
    });
    return (dispatch) => {
        user.confirmPassword(
            confirmationCode,
            newPassword,
            {
                onSuccess: (data) => {
                    dispatch(resetPasswordConfirmationAction(data));
                    navigate('/login');
                },
                onFailure: (err) => {
                    console.log (err);
                    if (err.name === 'ExpiredCodeException') {
                        startResetPassword(email);
                    }
                    formatError(err.name, err.message);
                },
            })
    }
}
export function startResetPasswordAction(data) {
    return {
        type: PASSWORD_RESET_STARTED_ACTION,
        payload: data,
    };
}
export function resetPasswordConfirmationAction(data) {
    return {
        type: PASSWORD_RESET_CONFIRMED_ACTION,
        payload: data,
    };
}

export function formatError(errorName, errorMessage) {
    switch (errorName) {
        case 'UsernameExistsInDatabaseException':
            swal("Oops", "Username and/or Email already exists", "error",{ button: "Try Again!",});
            break;
        case 'UsernameExistsException':
            //return 'Email already exists';
            swal("Oops", "Username and/or Email already exists", "error",{ button: "Try Again!",});
            break;
        case 'InvalidParameterException':
            //return One of the field has an error
            var errorWith = '';
            if (errorMessage.includes('username')){errorWith = errorWith.concat('Username ')}
            if (errorMessage.includes('email')){errorWith = errorWith.length > 0? errorWith.concat('/Email '): errorWith.concat('Email ')}
            if (errorMessage.includes('password')){errorWith = errorWith.length > 0? errorWith.concat('and password'): errorWith.concat('Password')}
            swal('Oops', "Error with ".concat(errorWith).trim(),"error",{ button: "Try Again!",})
            break;
        case 'InvalidPasswordException':
            //return 'Invalid Password';
            swal("Oops", "Password Does Not Meet Requirements", "error",{ button: "Try Again!",});
            break;
        case 'NotAuthorizedException':
            swal("Oops", "The Username or Password are incorrect", "error",{ button: "Try Again!",});
            break;
        case 'UserNotConfirmedException':
            swal("Oops", "Looks Like your account is not verified, We have sent another verification link to the registered email", "error",{ button: "Try Again!",});
            break;
        case 'TooManyRequestsException':
        case 'LimitExceededException' :
        case 'TooManyFailedAttemptsException':
            swal("Oops", errorMessage, "error",{ button: "Try Again!",});
            break;
        case 'CodeMismatchException':
            swal("Oops", "Code is Incorrect", "error",{ button: "Try Again!",});
            break;
        case 'ExpiredCodeException':
            swal("Oops", "Code has Expired, Another Code Has Been sent", "error",{ button: "Try Again!",});
            break;
        default:
            swal("Oops", "An Unknown Error Has Occurred", "error",{ button: "Try Again!",});;
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    localStorage.removeItem('userDetails');
    tokenDetails.expireDate = new Date(
        Date.now() + 3600000,
    );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        //dispatch(Logout(history));
        if(socket.connected) {socket.disconnect()};
        dispatch(Logout(navigate));
    }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(Logout(navigate));
		return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(Logout(navigate));
        return;
    }
		
    dispatch(loginConfirmedAction(tokenDetails));
	
    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, navigate);
}

export const RESET_MESSAGE_ACTION = '[reset action] reset messages';
export function reset_message_action() {
    return {
        type: RESET_MESSAGE_ACTION,
        payload: '',
    };
}
