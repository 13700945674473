export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';

export function Logout(navigate) {
	localStorage.removeItem('userDetails');
    navigate('/login');
	//history.push('/login');
    
	return {
        type: LOGOUT_ACTION,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
