import React, {useContext} from "react";
import { ThemeContext } from "../../../context/ThemeContext";

const CoinCard = ({current_price, price_change_percentage_24h, logo_picture, currency_symbol}) => {
    const { background } = useContext(ThemeContext);
  return (
    <>
      <div className="col-xl-3 col-sm-6 m-t35">
        <div className="card card-coin">
          <div className="card-body text-center">
            <img
            className="mb-3 currency-icon"
            width={80}
            height={90}
            viewBox="0 0 80 80"
            src={logo_picture}
            style={{'backgroundColor':
                background.value === 'dark'? "black":"#F6F6F6"
            }}
            />
            <h2 className="text-black mb-2 font-w600">
              {currency_symbol}{' '}{Number.parseFloat(current_price).toFixed(2)}
            </h2>
            <p className="mb-0 fs-14">
              <svg
                width="29"
                height="22"
                viewBox="0 0 29 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path
                    d = {price_change_percentage_24h < 0? "M5 4C5.91797 5.08433 8.89728 8.27228 10.5 10L16.5 7L23.5 16" :
                    "M5 16C5.91797 14.9157 8.89728 11.7277 10.5 10L16.5 13L23.5 4"}
                    stroke={
                      price_change_percentage_24h > 0
                        ? "#2BC155"
                        : "#FF2E2E"
                    }
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d1"
                    x="-3.05176e-05"
                    y="-6.10352e-05"
                    width="28.5001"
                    height="22.0001"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                      type="matrix"
                      values={
                        price_change_percentage_24h > 0
                          ? "0 0 0 0 0.172549 0 0 0 0 0.72549 0 0 0 0 0.337255 0 0 0 0.61 0"
                          : "0 0 0 0 1 0 0 0 0 0.180392 0 0 0 0 0.180392 0 0 0 0.61 0"
                      }
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
              <span
                className={
                  price_change_percentage_24h > 0
                    ? "text-success me-1"
                    : "text-danger me-1"
                }
              >
                {Number.parseFloat(price_change_percentage_24h).toFixed(
                  2
                )}
                %
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoinCard;
