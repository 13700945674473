import React from "react";

export function ResetPasswordConfirmation({
  code,
  setCode,
  newPassword,
  setNewPassword,
  newPasswordConfirmation,
  setNewPasswordConfirmation,
}) {
  return (
    <div className="form-group">
      <div className="mb-3">
        <label className="mb-2">
          <strong>Enter the 6 digit code below</strong>
        </label>
        <input
          className="form-control input"
          placeholder="XXXXXX"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
      </div>
      <div>
        <label className="mb-2">
          <strong>New Password</strong>
        </label>
        <input
          className="form-control input"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <label className="mb-2 mt-3">
          <strong>Confirm Password</strong>
        </label>
        <input
          className="form-control input"
          type="password"
          value={newPasswordConfirmation}
          onChange={(e) => setNewPasswordConfirmation(e.target.value)}
        />
      </div>
    </div>
  );
}

export default ResetPasswordConfirmation;
