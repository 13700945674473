import React, { useState, useEffect } from "react";
import MsgBox from "./MsgBox";

// TODO:
//  - ADD chat photos, should be pretty straight forward
//    change chats state to have chat name and chat photo, then use deconstruction in map to display the image

const Chat = ({
  PerfectScrollbar,
  toggleChatBox,
  toggleTab,
  socket,
  setNotifications,
}) => {
  const [openMsg, setOpenMsg] = useState({ flag: false, chatName: "" });
  const [daisyMessages, SetDaisyMessages] = useState([]);
  const [chats, setChats] = useState([
    { Header: "General Chats", chats: ["Alpha Chat", "Daisy"] },
    {
      Header: "Trending",
      chats: [
        "AAPL",
        "3M",
        "NVDA",
        "TSLA",
        "CAT",
        "JPM",
        "V",
        "DIS",
        "WMT",
        "HON",
      ],
    },
    { Header: "Your Watchlist", chats: [] },
  ]);
  const [messages, setMessages] = useState({
    "AAPL": [],
    "3M": [],
    "NVDA": [],
    "TSLA": [],
    "CAT": [],
    "JPM": [],
    "V": [],
    "DIS": [],
    "WMT": [],
    "HON": [],
    "Alpha Chat": [],
  });

  useEffect(() => {
    socket.on('retrievedMsgs', (data) => {
      setMessages(data['chats'])
    })

    socket.on("messageReceived", (data) => {
      setMessages((m) => (m[data.chatRoom] = [...m[data.chatRoom], data], m));
      document.getElementById(data.chatRoom).style.visibility = 'visible';
      setNotifications(noti => noti+1)
    });

  }, []);

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const username = userDetails.idToken.payload['cognito:username']

  const apiURL = 'https://api.alphalibertee.com/'
  useEffect(() => {
    fetch(apiURL.concat(`getDaisyMessages?username=${username}`), {
      method: 'GET'
    }).then((response) => {
      if (response.ok) {
        return response.json()
      }
      throw response;
    }).then((data) => {
      console.log(data)
      SetDaisyMessages(data['messages'])
    })
  }, [])
  
  return (
    <div
      className={`tab-pane fade  ${toggleTab === "chat" ? "active show" : ""}`}
      id="chat"
      role="tabpanel"
    >
      <div
        className={`card mb-sm-3 mb-md-0 contacts_card dz-chat-user-box ${
          openMsg.flag ? "d-none" : ""
        }`}
      >
        <div className="card-header chat-list-header text-center">
          <h4 className="mb-1" ref={(el) => el && el.style.setProperty('margin-bottom', 0, 'important')}>Chat List</h4>
        </div>
        <PerfectScrollbar
          className={`card-body contacts_body p-0 dz-scroll  ${
            toggleChatBox ? "ps ps--active-y" : ""
          }`}
          id="DZ_W_Contacts_Body"
          options={{ suppressScrollX: true, wheelPropagation: false }}
        >
          <ul className="contacts">
            {chats.map((subdiv_chats) => (
              <div key={subdiv_chats.Header}>
                <li className="chat_divider">{subdiv_chats.Header}</li>
                {subdiv_chats.chats.map((chat_name) => (
                  <div key={chat_name}>
                    <li
                      className="active dz-chat-user"
                      onClick={() =>
                        setOpenMsg({flag: true, chatName: chat_name})
                      }
                    >
                      <div className="d-flex bd-highlight">
                        <div className="img_cont">
                          
                        </div>
                        <div className="user_info">
                          <span>{chat_name !== "Alpha Chat" && chat_name !== "COMING SOON" ? '#'.concat(chat_name) : chat_name}</span>
                        </div>
                        <div
                          id={chat_name}
                          style={{ visibility: "hidden", color: "red" }}
                        >
                          *
                        </div>
                      </div>
                    </li>
                  </div>
                ))}
              </div>
            ))}
          </ul>
        </PerfectScrollbar>
      </div>
      <MsgBox
        openMsg={openMsg}
        PerfectScrollbar={PerfectScrollbar}
        offMsg={() => {
          document.getElementById(openMsg.chatName).style.visibility = "hidden";
          setOpenMsg(false);
        }}
        setMessages={openMsg.chatName === "Daisy"? SetDaisyMessages:setMessages}
        messages={openMsg.chatName === "Daisy"? daisyMessages:messages[openMsg.chatName]}
        socket={socket}
      />
    </div>
  );
};

export default Chat;
