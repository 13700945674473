import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import logo from '../../../images/Small_Logo.png';


const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } = useContext(
    ThemeContext
  );
  return (
    <div className="nav-header">
      <Link to="/dashboard" className="brand-logo">
        {background.value === "dark" || navigationHader !== "color_1" ? (
          <Fragment>
				    <img className="logo-abbr" width="50" height="50" viewBox="0 0 50 50" fill="none" src={logo}/>
            <span className="brand-title-black" width="74" height="22" viewBox="0 0 74 22" >Alpha Libertée</span>
          </Fragment>
        ) : (
          <Fragment>
				    <img className="logo-abbr" width="50" height="50" viewBox="0 0 50 50" fill="none" src={logo}/>
            <span className="brand-title-white" width="74" height="22" viewBox="0 0 74 22" >Alpha Libertée</span>
          </Fragment>
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >

        {/* Only appear for small devices */}
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
