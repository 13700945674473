import React, { Fragment, useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown, Container, Row, Col } from "react-bootstrap";
import IndexTab from "../zenix/Home/IndexTab";
import { ThemeContext } from "../../../context/ThemeContext";
import CoinCard from "./CoinCard";
import ethImage from "../../../images/coins/ethereum.webp";
import solImage from "../../../images/coins/solana-transparent.png";
import bitImage from "../../../images/coins/bitcoin.png";

const PieChart = loadable(() =>
  pMinDelay(import("../zenix/Portofolio/PieChart"), 1000)
);

const supportedCurrencies = [
  { name: "usd", symbol: "$" },
  { name: "cad", symbol: "$" },
  { name: "eur", symbol: "€" },
  { name: "jpy", symbol: "¥" },
  { name: "aed", symbol: "AED" },
];

const Home = () => {
  const { background } = useContext(ThemeContext);
  const [currency, setCurrency] = useState(supportedCurrencies[0]);

  // TODO:
  // This Array is temporary and should use the values returned from the backend in the future
  const [coinHolding, setHoldings] = useState({
    eth: 10,
    btc: 0.56,
    sol: 250,
  });

  const default_data = {"usd": 0.599847, "usd_24h_change": -1.929143398515384, "cad": 0.791255, "cad_24h_change": -2.2075242176094325, "jpy": 85.11, "jpy_24h_change": -1.8887081544039082, "eur": 0.548886, "eur_24h_change": -1.6135693123862669, "image": "https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png?1624446912"}

  const [bitcoin, setBitcoin] = useState(default_data);
  const [solana, setSolana] = useState(default_data);
  const [ethereum, setEthereum] = useState(default_data);
  const [trending, setTrending] = useState(default_data);

  useEffect(() => {
    fetch('https://api.alphalibertee.com/getCryptoPrices')
      .then((response) => response.json())
      .then((json) => {
        console.log(json)
        setBitcoin(json["bitcoin"])
        setSolana(json["solana"])
        setEthereum(json["ethereum"])
        setTrending(json["trending"])
      })
  }, []);

  var coinbg_colour, coin_font_colour = background.value === "dark" ? ("#2B2548","#F6F6F6") : ("#F6F6F6","#2B2548");
  // var coinbg_colour = background.value === "dark" ? "#2B2548" : "#F6F6F6";
  // var coin_font_colour = background.value === "dark" ? "#F6F6F6" : "#2B2548";
  return (
    <Fragment>
      <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
        <h2 className="font-w600 me-auto ">Dashboard</h2>
        <div className="weather-btn">
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              as="div"
              className="form-control style-1 default-select me-3 p-0"
            >
              {currency.name.toUpperCase()}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {supportedCurrencies.map(({ name, symbol }, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => setCurrency({ name, symbol })}
                >
                  {name.toUpperCase()}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="row">
        <CoinCard
          current_price={ethereum[currency.name]}
          price_change_percentage_24h={ethereum[`${currency.name}_24h_change`]}
          logo_picture={ethImage}
          currency_symbol={currency.symbol}
        />
        <CoinCard
          current_price={bitcoin[currency.name]}
          price_change_percentage_24h={bitcoin[`${currency.name}_24h_change`]}
          logo_picture={bitImage}
          currency_symbol={currency.symbol}
        />
        <CoinCard
          current_price={solana[currency.name]}
          price_change_percentage_24h={solana[`${currency.name}_24h_change`]}
          logo_picture={solImage}
          currency_symbol={currency.symbol}
        />
        <CoinCard
          current_price={trending[currency.name]}
          price_change_percentage_24h={trending[`${currency.name}_24h_change`]}
          logo_picture={trending.image}
          currency_symbol={currency.symbol}
        />
      </div>
      <div className="row">
        <div className="col-xl-8 col-xxl-8">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h4 className="mb-0 fs-20 text-black">Coin Holding</h4>
                </div>
                <div className="container-fluid card-body" style={{'padding':'1.875rem'}}>
                  <div className="row coin-holding justify-content-between">
                    <div className="col-3 mb-2 coin-bx">
                      <div className="d-flex align-items-center">
                        <div>
                          <svg
                            width="60"
                            height="60"
                            viewBox="0 0 60 60"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M30.5437 0.00501883C13.9681 -0.294993 0.305031 12.893 0.00501883 29.4562C-0.294993 46.0194 12.893 59.6949 29.4562 59.9949C46.0194 60.2949 59.6949 47.1069 59.9949 30.5312C60.2949 13.9681 47.1069 0.29253 30.5437 0.00501883ZM29.5562 54.3697C16.1182 54.1197 5.38023 42.9942 5.63024 29.5562C5.86775 16.1182 16.9932 5.38023 30.4312 5.61774C43.8818 5.86775 54.6072 16.9932 54.3697 30.4312C54.1322 43.8693 42.9942 54.6072 29.5562 54.3697Z"
                              fill={coin_font_colour}
                            />
                            <path
                              d="M30.3962 8.12284C18.3333 7.91034 8.34535 17.5482 8.13284 29.6112C7.90784 41.6617 17.5457 51.6496 29.6087 51.8746C41.6717 52.0871 51.6596 42.4492 51.8721 30.3987C52.0846 18.3358 42.4592 8.34785 30.3962 8.12284ZM30.0025 14.3581L36.954 26.7598L30.61 23.2297C30.2312 23.0197 29.7725 23.0197 29.3937 23.2297L23.0497 26.7598L30.0025 14.3581ZM30.0025 45.6381L23.0497 33.2364L29.3937 36.7665C29.5825 36.8715 29.7925 36.924 30.0012 36.924C30.21 36.924 30.42 36.8715 30.6087 36.7665L36.9528 33.2364L30.0025 45.6381ZM30.0025 34.2426L22.3722 29.9975L30.0025 25.7523L37.6315 29.9975L30.0025 34.2426Z"
                              fill={coin_font_colour}
                            />
                          </svg>
                        </div>
                        <div className="ms-3">
                          <h4 className="coin-font font-w600 mb-0">Ethereum</h4>
                          <p className="mb-0 op-6">ETH</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 mb-2">
                      <div className="d-flex align-items-center">
                        <div className="coin-bx-one">
                          <svg
                            width="33"
                            height="35"
                            viewBox="0 0 33 35"
                            fill={coin_font_colour}
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="4.71425"
                              height="34.5712"
                              rx="2.35713"
                              transform="matrix(-1 0 0 1 33 0)"
                              fill={coin_font_colour}
                            />
                            <rect
                              width="4.71425"
                              height="25.1427"
                              rx="2.35713"
                              transform="matrix(-1 0 0 1 23.5713 9.42853)"
                              fill={coin_font_colour}
                            />
                            <rect
                              width="4.71425"
                              height="10.9999"
                              rx="2.35713"
                              transform="matrix(-1 0 0 1 14.1436 23.5713)"
                              fill={coin_font_colour}
                            />
                            <rect
                              width="5.31864"
                              height="21.2746"
                              rx="2.65932"
                              transform="matrix(-1 0 0 1 5.31836 13.2966)"
                              fill={coin_font_colour}
                            />
                          </svg>
                        </div>
                        <div className="ms-3">
                          <h2 className="mb-0 coin-font-1">
                            {currency.symbol}{" "}
                            {Number.parseFloat(coinHolding.eth*ethereum[currency.name])
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 mb-2">
                      <div className="d-flex align-items-center">
                        <svg
                          width="21"
                          height="14"
                          viewBox="0 0 21 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 13C1.91797 11.9157 4.89728 8.72772 6.5 7L12.5 10L19.5 1"
                            stroke="#2BC155"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                        </svg>
                        <p className="mb-0 ms-2 text-success">45%</p>
                        <p className="mb-0 ms-2 font-w400">This Week</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 row coin-holding justify-content-between">
                    <div className="col-3 mb-2 coin-bx">
                      <div className="d-flex align-items-center">
                        <div>
                          <svg
                            width="60"
                            height="60"
                            viewBox="0 0 60 60"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M30 0C13.4312 0 0 13.4312 0 30C0 46.5688 13.4312 60 30 60C46.5688 60 60 46.5688 60 30C60 13.4312 46.5688 0 30 0ZM30 54.375C16.5587 54.375 5.625 43.44 5.625 30C5.625 16.56 16.5587 5.625 30 5.625C43.4413 5.625 54.375 16.5587 54.375 30C54.375 43.4413 43.44 54.375 30 54.375Z"
                              fill={coin_font_colour}
                            />
                            <path
                              d="M31.5488 30.9737H27.61V36.825H31.5488C32.3438 36.825 33.0813 36.5025 33.5988 35.9612C34.14 35.4425 34.4625 34.7062 34.4625 33.8875C34.4638 32.2862 33.15 30.9737 31.5488 30.9737Z"
                              fill={coin_font_colour}
                            />
                            <path
                              d="M30 8.12496C17.9375 8.12496 8.125 17.9375 8.125 30C8.125 42.0625 17.9375 51.875 30 51.875C42.0625 51.875 51.875 42.0612 51.875 30C51.875 17.9387 42.0612 8.12496 30 8.12496ZM34.4512 40.13H31.8712V44.185H29.165V40.13H27.6787V44.185H24.96V40.13H20.18V37.585H22.8175V22.335H20.18V19.79H24.96V15.8162H27.6787V19.79H29.165V15.8162H31.8712V19.79H34.2212C35.5337 19.79 36.7437 20.3312 37.6075 21.195C38.4712 22.0587 39.0125 23.2687 39.0125 24.5812C39.0125 27.15 36.985 29.2462 34.4512 29.3612C37.4225 29.3612 39.8187 31.78 39.8187 34.7512C39.8187 37.7112 37.4237 40.13 34.4512 40.13Z"
                              fill={coin_font_colour}
                            />
                            <path
                              d="M33.2888 27.38C33.7613 26.9075 34.0488 26.2737 34.0488 25.56C34.0488 24.1437 32.8975 22.9912 31.48 22.9912H27.61V28.14H31.48C32.1825 28.14 32.8275 27.84 33.2888 27.38Z"
                              fill={coin_font_colour}
                            />
                          </svg>
                        </div>
                        <div className="ms-3">
                          <h4 className="coin-font font-w600 mb-0">Bitcoin</h4>
                          <p className="mb-0">BTC</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 mb-2">
                      <div className="d-flex align-items-center">
                        <div className="coin-bx-one">
                          <svg
                            width="33"
                            height="35"
                            viewBox="0 0 33 35"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="4.71425"
                              height="34.5712"
                              rx="2.35713"
                              transform="matrix(-1 0 0 1 33 0)"
                              fill={coin_font_colour}
                            />
                            <rect
                              width="4.71425"
                              height="25.1427"
                              rx="2.35713"
                              transform="matrix(-1 0 0 1 23.5713 9.42853)"
                              fill={coin_font_colour}
                            />
                            <rect
                              width="4.71425"
                              height="10.9999"
                              rx="2.35713"
                              transform="matrix(-1 0 0 1 14.1436 23.5713)"
                              fill={coin_font_colour}
                            />
                            <rect
                              width="5.31864"
                              height="21.2746"
                              rx="2.65932"
                              transform="matrix(-1 0 0 1 5.31836 13.2966)"
                              fill={coin_font_colour}
                            />
                          </svg>
                        </div>
                        <div className="ms-3">
                          <h2 className="mb-0 coin-font-1">
                            {currency.symbol}{" "}
                            {Number.parseFloat(coinHolding.btc*bitcoin[currency.name])
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 mb-2">
                      <div className="d-flex align-items-center">
                        <svg
                          width="29"
                          height="22"
                          viewBox="0 0 29 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g filter="url(#filter0_d)">
                            <path
                              d="M5 4C5.91797 5.08433 8.89728 8.27228 10.5 10L16.5 7L23.5 16"
                              stroke="#FF2E2E"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </g>
                          <defs>
                            <filter
                              id="filter0_d"
                              x="0"
                              y="0"
                              width="28.5001"
                              height="22.0001"
                              filterUnits="userSpaceOnUse"
                              colorInterpolationFilters="sRGB"
                            >
                              <feFlood
                                floodOpacity="0"
                                result="BackgroundImageFix"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              />
                              <feOffset dy="1" />
                              <feGaussianBlur stdDeviation="2" />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 1 0 0 0 0 0.180392 0 0 0 0 0.180392 0 0 0 0.61 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow"
                                result="shape"
                              />
                            </filter>
                          </defs>
                        </svg>

                        <p className="mb-0 ms-2 text-danger">45%</p>
                        <p className="mb-0 ms-2 font-w400">This Week</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 row coin-holding justify-content-between">
                    <div className="col-3 mb-2 coin-bx">
                      <div className="d-flex align-items-center">
                        <div>
                          <svg
                            width="60"
                            height="60"
                            viewBox="0 0 60 60"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M30 8.125C17.9375 8.125 8.125 17.9375 8.125 30 8.125 42.0625 17.9375 51.875 30 51.875 42.0625 51.875 51.875 42.0612 51.875 30 51.875 17.9387 42.0612 8.125 30 8.125Zm11 33.875L14 42 19 36 46 36ZM46 33 19 33 14 27 41 27ZM41 24 14 24 19 18 46 18Z"
                              fill={coin_font_colour}
                            />
                            <path
                              d="M30 0C13.4312 0 0 13.4312 0 30C0 46.5688 13.4312 60 30 60C46.5688 60 60 46.5688 60 30C60 13.4312 46.5688 0 30 0ZM30 54.375C16.5587 54.375 5.625 43.44 5.625 30C5.625 16.56 16.5587 5.625 30 5.625C43.4413 5.625 54.375 16.5587 54.375 30C54.375 43.4413 43.44 54.375 30 54.375Z"
                              fill={coin_font_colour}
                            />
                          </svg>
                        </div>
                        <div className="ms-3">
                          <h4 className="coin-font font-w600 mb-0">Solana</h4>
                          <p className="mb-0">SOL</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 mb-2">
                      <div className="d-flex align-items-center">
                        <div className="coin-bx-one">
                          <svg
                            width="33"
                            height="35"
                            viewBox="0 0 33 35"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="4.71425"
                              height="34.5712"
                              rx="2.35713"
                              transform="matrix(-1 0 0 1 33 0)"
                              fill={coin_font_colour}
                            />
                            <rect
                              width="4.71425"
                              height="25.1427"
                              rx="2.35713"
                              transform="matrix(-1 0 0 1 23.5713 9.42853)"
                              fill={coin_font_colour}
                            />
                            <rect
                              width="4.71425"
                              height="10.9999"
                              rx="2.35713"
                              transform="matrix(-1 0 0 1 14.1436 23.5713)"
                              fill={coin_font_colour}
                            />
                            <rect
                              width="5.31864"
                              height="21.2746"
                              rx="2.65932"
                              transform="matrix(-1 0 0 1 5.31836 13.2966)"
                              fill={coin_font_colour}
                            />
                          </svg>
                        </div>
                        <div className="ms-3">
                          <h2 className="mb-0 coin-font-1">
                            {currency.symbol}{" "}
                            {Number.parseFloat(coinHolding.sol*solana[currency.name])
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 mb-2">
                      <div className="d-flex align-items-center">
                        <svg
                          width="21"
                          height="14"
                          viewBox="0 0 21 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 13C1.91797 11.9157 4.89728 8.72772 6.5 7L12.5 10L19.5 1"
                            stroke="#2BC155"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                        </svg>
                        <p className="mb-0 ms-2 text-success">45%</p>
                        <p className="mb-0 ms-2 font-w400">This Week</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-12 col-sm-12">
          <div className="card">
            <div className="card-header border-0">
              <h4 className="mb-0 text-black fs-20">Current Graph</h4>
            </div>
            <div className="card-body text-center" style={{'paddingTop':'1.75rem','paddingBottom':'1.75rem'}}>
              <div id="pieChart" className="d-inline-block">
                <PieChart
                  series={[
                    coinHolding.btc*bitcoin[currency.name],
                    coinHolding.eth*ethereum[currency.name],
                    coinHolding.sol*solana[currency.name],
                  ]}
                />
              </div>
              <div className="chart-items">
                <div className=" col-xl-12 col-sm-12">
                  <div className="row text-black text-left fs-13 mt-4">
                    <span className="mb-3 col-6">
                      <svg
                        className="me-2"
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="19" height="19" rx="9.5" fill="#374B96" />
                      </svg>
                      Bitcoin
                    </span>
                    <span className="mb-3 col-6">
                      <svg
                        className="me-2"
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="19" height="19" rx="9.5" fill="#F7A62C" />
                      </svg>
                      Ethereum
                    </span>
                    <span className="mb-3 col-6">
                      <svg
                        className="me-2"
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="19" height="19" rx="9.5" fill="#FF782C" />
                      </svg>
                      Solana
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 col-xxl-12">
          <div className="row">
            <div className="col-xl-12">
              <IndexTab />
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-xxl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header d-sm-flex d-block pb-0 border-0">
                  <div>
                    <h4 className="fs-20 text-black">Quick Trade</h4>
                    <p className="mb-0 fs-12">
                      Lorem ipsum dolor sit amet, consectetur
                    </p>
                  </div>
                  <Dropdown className="custom-dropdown d-block mt-3 mt-sm-0 mb-0">
                    <Dropdown.Toggle
                      variant=""
                      className="btn btn-light btn-sm d-flex align-items-center svg-btn i-false"
                      data-toggle="dropdown"
                    >
                      <svg
                        className="me-2"
                        width="25"
                        height="25"
                        viewBox="0 0 42 42"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M28.5 16.5002C28.4986 14.844 27.156 13.5018 25.5003 13.5H16.5002V19.4999H25.5003C27.156 19.4985 28.4986 18.1559 28.5 16.5002Z"
                          fill="#FFAB2D"
                        />
                        <path
                          d="M16.5002 28.5H25.5003C27.1569 28.5 28.5 27.1569 28.5 25.5003C28.5 23.8432 27.1569 22.5001 25.5003 22.5001H16.5002V28.5Z"
                          fill="#FFAB2D"
                        />
                        <path
                          d="M21 9.15527e-05C9.40213 9.15527e-05 0.00012207 9.4021 0.00012207 21C0.00012207 32.5979 9.40213 41.9999 21 41.9999C32.5979 41.9999 41.9999 32.5979 41.9999 21C41.9867 9.40759 32.5924 0.0133667 21 9.15527e-05ZM31.5002 25.4998C31.4961 28.8122 28.8122 31.4961 25.5003 31.4997V32.9998C25.5003 33.8284 24.8283 34.4999 24.0002 34.4999C23.1717 34.4999 22.5001 33.8284 22.5001 32.9998V31.4997H19.5004V32.9998C19.5004 33.8284 18.8284 34.4999 18.0003 34.4999C17.1718 34.4999 16.5002 33.8284 16.5002 32.9998V31.4997H12.0004C11.1718 31.4997 10.5003 30.8282 10.5003 30.0001C10.5003 29.1715 11.1718 28.5 12.0004 28.5H13.5V13.5H12.0004C11.1718 13.5 10.5003 12.8285 10.5003 11.9999C10.5003 11.1714 11.1718 10.4998 12.0004 10.4998H16.5002V9.00018C16.5002 8.17163 17.1718 7.50009 18.0003 7.50009C18.8289 7.50009 19.5004 8.17163 19.5004 9.00018V10.4998H22.5001V9.00018C22.5001 8.17163 23.1717 7.50009 24.0002 7.50009C24.8288 7.50009 25.5003 8.17163 25.5003 9.00018V10.4998C28.7999 10.4861 31.486 13.1494 31.5002 16.4489C31.5075 18.1962 30.7499 19.8593 29.4265 21C30.7376 22.1279 31.4943 23.7699 31.5002 25.4998Z"
                          fill="#FFAB2D"
                        />
                      </svg>
                      <span className="text-black fs-16">Yearly (2023)</span>
                      <i className="fa fa-angle-down text-black scale3 ms-2"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="dropdown-menu-right"
                      alignRight={true}
                    >
                      <Link className="dropdown-item" to="#">
                        {" "}
                        Weekly (2023){" "}
                      </Link>
                      <Link className="dropdown-item" to="#">
                        {" "}
                        Daily (2023)
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="card-body">
                  <div className="basic-form">
                    <form className="form-wrapper">
                      <div className="form-group">
                        <div className="input-group input-group-lg">
                          <div className="input-group-prepend">
                            <span className="input-group-text">Amount BTC</span>
                          </div>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="52.5"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group input-group-lg">
                          <div className="input-group-prepend">
                            <span className="input-group-text ">Price BPL</span>
                          </div>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="0,000000"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group input-group-lg">
                          <div className="input-group-prepend">
                            <span className="input-group-text">Fee (1%)</span>
                          </div>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="0,000000"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group input-group-lg">
                          <div className="input-group-prepend">
                            <span className="input-group-text">Total BPL</span>
                          </div>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="0,000000"
                          />
                        </div>
                      </div>
                      <div className="row mt-4 align-items-center">
                        <div className="col-lg-6">
                          <div>
                            <p className="mb-0 fs-14">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="d-flex justify-content-end">
                            <Link
                              to={"#"}
                              className="btn  btn-success text-white text-nowrap"
                            >
                              BUY
                              <svg
                                className="ms-3 scale3"
                                width="16"
                                height="16"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.9638 11.5104L16.9721 14.9391L3.78954 1.7565C3.22815 1.19511 2.31799 1.19511 1.75661 1.7565C1.19522 2.31789 1.19522 3.22805 1.75661 3.78943L14.9392 16.972L11.5105 16.9637L11.5105 16.9637C10.7166 16.9619 10.0715 17.6039 10.0696 18.3978C10.0677 19.1919 10.7099 19.8369 11.5036 19.8388L11.5049 19.3388L11.5036 19.8388L18.3976 19.8554L18.4146 19.8555L18.4159 19.8555C18.418 19.8555 18.42 19.8555 18.422 19.8555C19.2131 19.8533 19.8528 19.2114 19.8555 18.4231C19.8556 18.4196 19.8556 18.4158 19.8556 18.4117L19.8389 11.5035L19.8389 11.5035C19.8369 10.7097 19.1919 10.0676 18.3979 10.0695C17.604 10.0713 16.9619 10.7164 16.9638 11.5103L16.9638 11.5104Z"
                                  fill="white"
                                  stroke="white"
                                ></path>
                              </svg>
                            </Link>
                            <Link
                              to={"#"}
                              className="btn btn-danger ms-3 text-nowrap"
                            >
                              SELL
                              <svg
                                className="ms-3 scale5"
                                width="16"
                                height="16"
                                viewBox="0 0 29 29"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.35182 13.4965L5.35182 13.4965L5.33512 6.58823C5.33508 6.5844 5.3351 6.58084 5.33514 6.57759M5.35182 13.4965L5.83514 6.58306L5.33514 6.58221C5.33517 6.56908 5.33572 6.55882 5.33597 6.5545L5.33606 6.55298C5.33585 6.55628 5.33533 6.56514 5.33516 6.57648C5.33515 6.57684 5.33514 6.57721 5.33514 6.57759M5.35182 13.4965C5.35375 14.2903 5.99878 14.9324 6.79278 14.9305C7.58669 14.9287 8.22874 14.2836 8.22686 13.4897L8.22686 13.4896L8.21853 10.0609M5.35182 13.4965L8.21853 10.0609M5.33514 6.57759C5.33752 5.789 5.97736 5.14667 6.76872 5.14454C6.77041 5.14452 6.77217 5.14451 6.77397 5.14451L6.77603 5.1445L6.79319 5.14456L13.687 5.16121L13.6858 5.66121L13.687 5.16121C14.4807 5.16314 15.123 5.80809 15.1211 6.6022C15.1192 7.3961 14.4741 8.03814 13.6802 8.03626L13.6802 8.03626L10.2515 8.02798L23.4341 21.2106C23.9955 21.772 23.9955 22.6821 23.4341 23.2435C22.8727 23.8049 21.9625 23.8049 21.4011 23.2435L8.21853 10.0609M5.33514 6.57759C5.33513 6.57959 5.33514 6.58159 5.33514 6.5836L8.21853 10.0609M6.77407 5.14454C6.77472 5.14454 6.77537 5.14454 6.77603 5.14454L6.77407 5.14454Z"
                                  fill="white"
                                  stroke="white"
                                ></path>
                              </svg>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Home;
